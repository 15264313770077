import { useEffect } from "react";

const REDIRECTS = [
  {
    source:
      "/laapffvirtual/play/5ec8072873621001ffab17be/5eb4626c6c346303eea6f64b",
    destination:
      "/laapffvirtual/play/5ec8072873621001ffab17be/5ecd7535600885008558c3a1"
  },
  {
    source: "/fksjfhskdfukjfd/play/5f8cae40eec5900148cd44f8",
    destination: "/nyrphulaween/play/5f8cae40eec5900148cd44f8"
  },
  {
    source: "/fksjfhskdfukjfd/play/5f8c9dc8a40faf0061d66d32",
    destination: "/nyrphulaween/play/5f8c9dc8a40faf0061d66d32"
  },
  {
    sourceTest: /^\/sdijff(?:$|\/)/,
    source: "/sdijff",
    destination: "/sdijff2021"
  },
  {
    sourceTest: /^\/tmff(?:$|\/)/,
    source: "/tmff",
    destination: "/tmffcinema"
  },
  {
    sourceTest: /^\/eventivevirtualfest(?:$|\/)/,
    source: "/eventivevirtualfest",
    destination: "/eventivevirtual"
  },
  {
    source:
      "/themeyoucantsee/play/60b3ae1ef9945f0045a22312/60b3bd1c2856dd0098f116dc",
    destination: "/themeyoucantsee/play/60b3ae1ef9945f0045a22312"
  },
  {
    sourceTest: /^\/bigwater2021(?:$|\/)/,
    source: "/bigwater2021",
    destination: "/14thbigwater"
  }
];

export default function useSystemRedirects(router) {
  useEffect(() => {
    for (const { source, sourceTest, destination } of REDIRECTS) {
      if (
        sourceTest
          ? sourceTest.test(router.asPath)
          : router.asPath.startsWith(source)
      ) {
        router.replace(destination + router.asPath.replace(source, ""));
      }
    }
  }, []);
}
