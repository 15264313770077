import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stream-chat-react/dist/css/index.css";
import "plyr/dist/plyr.css";
import "../styles.css";

import * as Sentry from "@sentry/node";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE
    ? `eventive-watch@${process.env.SENTRY_RELEASE}`
    : undefined,
  beforeSend: (event, hint) => {
    if (hint) {
      const error = hint.originalException;
      let errorMessage;
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      if (
        /indexOf/.test(errorMessage) ||
        /status code 502/.test(errorMessage) ||
        /Network Error/.test(errorMessage) ||
        /Loading chunk \d+ failed/.test(errorMessage) ||
        /Failed to load Stripe/.test(errorMessage)
      ) {
        return null;
      }
    }
    return event;
  }
});

import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import { appWithTranslation } from "next-i18next";

import { GlobalExpandableManagerProvider } from "../hooks/useGlobalExpandableManager";
import { AuthProvider, useAuth } from "../hooks/useAuth";
import useSystemRedirects from "../hooks/useSystemRedirects";
import { fetchTenantConfig } from "../services/api";
import Layout from "../components/Layout";

import dynamic from "next/dynamic";
const LoginExpiredModal = dynamic(() =>
  import("../components/LoginExpiredModal")
);
const LoginModal = dynamic(() => import("../components/LoginModal"));

import smoothscroll from "smoothscroll-polyfill";
if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

if (typeof window !== "undefined") {
  import("../utils/iframe-resizer");
  import("../utils/experiments");
}

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

Router.events.on("routeChangeComplete", url => {
  setTimeout(() => {
    window.gtag("config", process.env.GOOGLE_ANALYTICS_TRACKING_ID, {
      page_location: url,
      page_title: document.title
    });

    // Custom tracking scripts for customer sites
    if (window.__ev_gat) {
      window.ga(`${window.__ev_gat}.set`, "location", url);
      window.ga(`${window.__ev_gat}.set`, "title", document.title);
      window.ga(`${window.__ev_gat}.send`, "pageview");
    }
    if (window.__ev_fbpt) {
      window.fbq("trackSingle", window.__ev_fbpt, "PageView");
    }
  }, 100);
});

function MyAppComponent({ Component, ...rest }) {
  const router = useRouter();
  const { tenant, singleOrganization } = useAuth();
  useSystemRedirects(router);

  useEffect(() => {
    if (router.query.ida_auth || router.query.eventival_auth) {
      rest.requestLoginModal();
    }
  }, []);

  if (router.query.festivalId && !tenant) {
    return <Layout loader={true} />;
  }

  const faviconUrl =
    singleOrganization && tenant?.favicon
      ? tenant.favicon
      : "https://eventive.imgix.net/app_icon.png";

  return (
    <>
      <Head>
        <meta
          name="apple-mobile-web-app-title"
          content={tenant?.display_name}
        />
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1,user-scalable=no"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${faviconUrl}?fm=png&w=180`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${faviconUrl}?fm=png&w=32`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${faviconUrl}?fm=png&w=16`}
        />
        <script src="https://js.stripe.com/v3/"></script>
        <script
          defer
          src="https://www.gstatic.com/cv/js/sender/v1/cast_sender.js"
        ></script>
        <script
          defer
          src="https://src.litix.io/shakaplayer/4/shakaplayer-mux.js"
        ></script>
        <script
          type="text/javascript"
          src="https://cdn.bitmovin.com/player/web/8/bitmovinplayer.js"
          async
        ></script>
        <script
          type="text/javascript"
          src="https://src.litix.io/bitmovin/3/bitmovin-mux.js"
          async
        ></script>
      </Head>
      <Component {...rest} />
    </>
  );
}

const MyApp = ({ Component, pageProps }) => {
  const [showLoginExpiredModal, setShowLoginExpiredModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <AuthProvider
      initialTenant={pageProps?.initialTenant}
      triggerLoginExpiredModal={() => setShowLoginExpiredModal(true)}
    >
      <GlobalExpandableManagerProvider>
        <MyAppComponent
          Component={Component}
          requestLoginModal={() => setShowLoginModal(true)}
          {...pageProps}
        />
        {showLoginExpiredModal ? (
          <LoginExpiredModal onClose={() => setShowLoginExpiredModal(false)} />
        ) : null}
        {showLoginModal ? (
          <LoginModal
            allowBackgroundDimiss
            onSuccess={() => setShowLoginModal(false)}
            onBackgroundDismiss={() => setShowLoginModal(false)}
          />
        ) : null}
      </GlobalExpandableManagerProvider>
    </AuthProvider>
  );
};

export default appWithTranslation(MyApp);
